
* {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
    background: 0 0;
    font-weight: 400;
    font-style: normal;
    text-decoration: none;
    outline: 0;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}
input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="text"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0;
}
input[type="button"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
input[type="number"] {
    -moz-appearance: textfield;
}
ol,
ul {
    list-style: none;
}
blockquote {
    quotes: none;
}
blockquote:after,
blockquote:before {
    content: "";
    content: none;
}
del {
    text-decoration: line-through;
}
address,
article,
aside,
footer,
header,
nav,
section {
    display: block;
}
body {
    font-family: gordita_regular;
    font-size: 17px;
    color: #545454;
    background-color: #161619;
    overflow-x: hidden;
    min-width: 320px;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

body::-webkit-scrollbar {
    display: none;
}
input,
select,
textarea {
    font-family: gordita_regular;
}
html {
    overflow: scroll;
    scroll-behavior: smooth !important;
}
.areas::-webkit-input-placeholder {
    color: #9b9a9a;
}
html.modal-enabled {
    overflow: hidden !important;
}
a {
    display: inline-block;
    color: #545454;
    text-decoration: none;
}
.submit:hover,
a:hover {
    -moz-opacity: 0.85;
    -khtml-opacity: 0.85;
    opacity: 0.85;
}
.video-player-wrapper video {
    vertical-align: middle;
    width: 50%;
}



.thumb {
    display: block;
}
.thumb img {
    display: block;
    width: 100%;
}
img {
    display: block;
    width: 100%;
}
p {
    line-height: 1.6em;
    font-size: 16px;
    color: #545454;
    font-family: gordita_regular;
}
.hidden {
    display: none;
}
br.clear {
    clear: both;
    line-height: 0;
}
.bold {
    font-family: gordita_bold;
}
.e-t-h {
    margin-bottom: 25px;
}
.e-t-h2 {
    font-size: 37px;
    color: #444;
    font-family: gordita_bold;
    letter-spacing: 0.02rem;
}
.wrapper {
    padding-top: 67px;
    margin-left: 217px;
    /* transition: margin 0.3s ease-in-out; */
    /* margin-left: 160px; */
    position: unset !important;
}
.g-regular {
    font-family: gordita_regular;
}
.g-medium {
    font-family: gordita_medium;
}
.g-semi {
    font-family: gordita_bold;
}
.white {
    color: #fff;
}
.black {
    color: #000;
}
a,
h1,
h2,
input {
    font-family: gordita_regular;
    color: #000;
}
input::-webkit-input-placeholder {
    color: #9b9a9a;
}
input:-moz-placeholder {
    color: #9b9a9a;
}
@font-face {
    font-display: auto;
    font-family: gordita_regular;
    src: url(../fonts/gordita/gordita-regular.woff) format("woff");
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-display: auto;
    font-family: gordita_medium;
    src: url(../fonts/gordita/gordita-medium.woff) format("woff");
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: gordita_bold;
    src: url(../fonts/gordita/gordita-bold.woff) format("woff");
    font-weight: 400;
    font-style: normal;
}
#main {
    padding: 66px 10px 10px 260px;
}

html.modal-enabled {
    overflow: hidden;
}
.video-player-wrapper {
    width: 800px !important;
}
@media all and (max-width: 1280px) {
    ul.paginationButtons {
        width: 40%;
    }
}
@media all and (max-width: 1080px) {
    li.previous {
        width: 105px !important;
    }
    li.next {
        width: 105px !important;
    }
    .video-player-wrapper {
        width: 700px !important;
    }
}
@media all and (max-width: 980px) {
    .wrapper {
        margin-left: 70px;
    }
    ul.paginationButtons {
        width: 50%;
    }
    ul.paginationButtons li {
        font-size: 13px;
    }
    li.previous {
        width: 98px !important;
    }
    li.next {
        width: 98px !important;
    }
    .video-player-wrapper {
        width: 600px !important;
    }
}
@media all and (max-width: 768px) {
    ul.paginationButtons {
        width: 58%;
    }
    .video-player-wrapper {
        width: 500px !important;
    }
}
@media all and (max-width: 640px) {
    ul.paginationButtons {
        width: 80%;
    }
    ul.paginationButtons li {
        height: 45px !important;
    }
    li.previous {
        width: 90px !important;
        height: 45px !important;
    }
    li.next {
        width: 90px !important;
        height: 45px !important;
    }
    .video-player-wrapper {
        width: 400px !important;
    }
}
@media all and (max-width: 480px) {
    .wrapper {
        margin-left: 0;
    }
    ul.paginationButtons {
        width: 91%;
    }
    li.previous {
        width: 80px !important;
    }
    li.next {
        width: 80px !important;
    }
    .video-player-wrapper {
        width: 300px !important;
    }


.progress-control {
    z-index: 50;
    opacity: 1;
    transition: all ease 0.5s;
    transform: unset;
}
.progress-control .hide {
    transform: translateY(20px);
    z-index: 10;
    opacity: 0;
    transition: all ease 0.5s;
}
.video-react {
    background-color: transparent !important;
}
.video-react .video-react-big-play-button {
  
    width: 60px;
    height: 60px;
    border-radius: 30px;
    line-height: 60px;
    border: none;
    position: absolute;
    top: 38%;
    left: 0;
    right: 0;
    margin: 0 auto;
}
.hide-player {
    display: none;
}
.video-react .video-react-control-bar {
    flex-wrap: wrap;
}

.video-react .video-react-progress-control {
    align-items: flex-start;
    width: 100%;
}
.video-react .video-react-control {
    height: auto;
}

/* .video-react .video-react-slider {
    margin: 0;
} */
.video-react .video-react-progress-holder {
    height: 5px;
}

.video-react .video-react-progress-holder .video-react-play-progress,
.video-react .video-react-progress-holder .video-react-load-progress,
.video-react .video-react-progress-holder .video-react-tooltip-progress-bar,
.video-react .video-react-progress-holder .video-react-load-progress div {
    height: 5px;
}

.video-react .video-react-play-progress:before {
    font-size: 17px;
    color: #15bf81;
}

.video-react .video-react-progress-holder .video-react-play-progress {
    background: #0fa76f;
}

.video-react .video-react-slider:focus {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.video-react .video-react-control-bar {
    height: 40px;
}
.fullscreen {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

#video-player .video-react .video-react-play-progress:before {
    color: #15bf81;
    font-size: 20px;
    line-height: 16px;
}
#video-player .video-react .video-react-mouse-display:after,
#video-player .video-react .video-react-play-progress:after,
#video-player .video-react .video-react-time-tooltip {
    font-family: "gordita_regular";
}
#video-player
    .video-react
    .video-react-progress-holder
    .video-react-play-progress {
    background: linear-gradient(
        272deg,
        rgba(34, 193, 195, 1) 0%,
        rgba(99, 187, 76, 1) 0%,
        rgba(24, 152, 175, 1) 100%
    );
}
#video-player
    .video-react
    .video-react-progress-holder
    .video-react-play-progress,
#video-player .video-react .video-react-progress-holder,
#video-player
    .video-react
    .video-react-progress-holder
    .video-react-load-progress,
#video-player
    .video-react
    .video-react-progress-holder
    .video-react-tooltip-progress-bar,
#video-player
    .video-react
    .video-react-progress-holder
    .video-react-load-progress
    div {
    height: 3px;
    border-radius: 15px;
}
#video-player .video-react .video-react-load-progress {
    background: #f5f5f5;
}

#video-player
    .video-react
    .video-react-big-play-button.video-react-big-play-button-center {
    top: 50%;
    left: 50%;
    margin-top: unset;
    margin-left: unset;
    transform: translate(-50%, -50%);
}
#video-player.hidden .video-react .video-react-control-bar {
    background: rgba(0, 0, 0, 0) !important;
    transition: all ease 0.5s;
    opacity: 0;
}

#video-player .video-react .video-react-control-bar {
    height: 100%;
    background: rgba(0, 0, 0, 0.25);
    transition: all ease 0.5s;
    opacity: 1;
}
#video-player .video-react .video-react-control-bar .bottom {
}
/* #video-player .video-react {
  font-size: 13px;
  font-family: unset;
  padding-top: 56.25%;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
} */
#video-player .video-react .video-react-control {
    padding: 0 30px;
    height: 63px;
    position: absolute;
    bottom: 0;
}
@media only screen and (max-width: 640px) {
    #video-player .video-react .video-react-control {
        padding: 0 30px;
        height: 45px;
        position: absolute;
        bottom: 0;
    }
    .video-react .video-react-play-progress:before {
        font-size: 17px;
        color: #15bf81;
    }
    #video-player .video-react .video-react-play-progress:before {
        color: #15bf81;
        font-size: 17px;
        line-height: 15px;
    }
}

@media only screen and (max-width: 480px) {
    #video-player .video-react .video-react-control {
        padding: 0 10px;
        height: 40px;
    }
    .video-react .video-react-play-progress:before {
        font-size: 15px;
        color: #15bf81;
    }
    #video-player .video-react .video-react-play-progress:before {
        color: #15bf81;
        font-size: 15px;
        line-height: 13px;
    }
}
#video-player .video-react .slide-left {
    transform: translateX(-100%);
    transition: transform 500ms ease-in-out;
}
.play-pause {
    display: flex;
    align-items: center;
    justify-content: center;
}
.play-pause label {
    display: block;
    box-sizing: border-box;
    width: 0;
    height: 25px;
    cursor: pointer;
    border-color: transparent transparent transparent #fff;
    transition: 100ms all ease;
    will-change: border-width;
    border-style: double;
    border-width: 0px 0 0px 34px;
}
.play-pause input[type="checkbox"] {
    visibility: hidden;
}
.play-pause input[type="checkbox"]:checked + label {
    border-style: solid;
    border-width: 22px 0 22px 36px;
}

#video-player .v-play-button {
    border: 0;
    background: transparent;
    box-sizing: border-box;
    width: 0;
    height: 25px;
    border-color: transparent transparent transparent #fff;
    transition: 100ms all ease;
    cursor: pointer;
    border-style: solid;
    border-width: 25px 0 25px 39px;
}
#video-player .v-play-button.playing {
    border-style: double;
    border-width: 0px 0 0px 41px;
}
#video-player .v-play-button:hover {
    border-color: transparent transparent transparent #fff;
}

.progress-control {
	z-index: 50;
	opacity: 1;
	transition: all ease 0.5s;
	transform: unset;
}
.progress-control .hide {
	transform: translateY(20px);
	z-index: 10;
	opacity: 0;
	transition: all ease 0.5s;
}
.video-react {
	background-color: transparent !important;
}
.video-react .video-react-big-play-button {
	width: 60px;
	height: 60px;
	border-radius: 30px;
	line-height: 60px;
	border: none;
}
.hide-player {
	display: none;
}
.video-react .video-react-control-bar {
	flex-wrap: wrap;
}

.video-react .video-react-progress-control {
	align-items: flex-start;
	width: 100%;
}
.video-react .video-react-control {
	height: auto;
}

/* .video-react .video-react-slider {
    margin: 0;
} */
.video-react .video-react-progress-holder {
	height: 5px;
}

.video-react .video-react-progress-holder .video-react-play-progress,
.video-react .video-react-progress-holder .video-react-load-progress,
.video-react .video-react-progress-holder .video-react-tooltip-progress-bar,
.video-react .video-react-progress-holder .video-react-load-progress div {
	height: 5px;
}

.video-react .video-react-play-progress:before {
	font-size: 17px;
	color: #15bf81;
}

.video-react .video-react-progress-holder .video-react-play-progress {
	background: #0fa76f;
}

.video-react .video-react-slider:focus {
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
}

.video-react .video-react-control-bar {
	height: 40px;
}
.fullscreen {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

#video-player .video-react .video-react-play-progress:before {
	color: #15bf81;
	font-size: 20px;
	line-height: 16px;
}
#video-player .video-react .video-react-mouse-display:after,
#video-player .video-react .video-react-play-progress:after,
#video-player .video-react .video-react-time-tooltip {
	font-family: "gordita_regular";
}
#video-player
	.video-react
	.video-react-progress-holder
	.video-react-play-progress {
	background: linear-gradient(
		272deg,
		rgba(34, 193, 195, 1) 0%,
		rgba(99, 187, 76, 1) 0%,
		rgba(24, 152, 175, 1) 100%
	);
}
#video-player
	.video-react
	.video-react-progress-holder
	.video-react-play-progress,
#video-player .video-react .video-react-progress-holder,
#video-player
	.video-react
	.video-react-progress-holder
	.video-react-load-progress,
#video-player
	.video-react
	.video-react-progress-holder
	.video-react-tooltip-progress-bar,
#video-player
	.video-react
	.video-react-progress-holder
	.video-react-load-progress
	div {
	height: 3px;
	border-radius: 15px;
}
#video-player .video-react .video-react-load-progress {
	background: #f5f5f5;
}

#video-player
	.video-react
	.video-react-big-play-button.video-react-big-play-button-center {
	top: 50%;
	left: 50%;
	margin-top: unset;
	margin-left: unset;
	transform: translate(-50%, -50%);
}
#video-player.hidden .video-react .video-react-control-bar {
	background: rgba(0, 0, 0, 0) !important;
	transition: all ease 0.5s;
	opacity: 0;
}

#video-player .video-react .video-react-control-bar {
	height: 100%;
	background: rgba(0, 0, 0, 0.25);
	transition: all ease 0.5s;
	opacity: 1;
}
#video-player .video-react .video-react-control-bar .bottom {
}
/* #video-player .video-react {
  font-size: 13px;
  font-family: unset;
  padding-top: 56.25%;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
} */
#video-player .video-react .video-react-control {
	padding: 0 30px;
	height: 63px;
	position: absolute;
	bottom: 0;
}
@media only screen and (max-width: 640px) {
	#video-player .video-react .video-react-control {
		padding: 0 30px;
		height: 45px;
		position: absolute;
		bottom: 0;
	}
	.video-react .video-react-play-progress:before {
		font-size: 17px;
		color: #15bf81;
	}
	#video-player .video-react .video-react-play-progress:before {
		color: #15bf81;
		font-size: 17px;
		line-height: 15px;
	}
}

@media only screen and (max-width: 480px) {
	#video-player .video-react .video-react-control {
		padding: 0 10px;
		height: 40px;
	}
	.video-react .video-react-play-progress:before {
		font-size: 15px;
		color: #15bf81;
	}
	#video-player .video-react .video-react-play-progress:before {
		color: #15bf81;
		font-size: 15px;
		line-height: 13px;
	}
	br.meet {
		display: none;
	}
}
#video-player .video-react .slide-left {
	transform: translateX(-100%);
	transition: transform 500ms ease-in-out;
}
.play-pause {
	display: flex;
	align-items: center;
	justify-content: center;
}
.play-pause label {
	display: block;
	box-sizing: border-box;
	width: 0;
	height: 25px;
	cursor: pointer;
	border-color: transparent transparent transparent #fff;
	transition: 100ms all ease;
	will-change: border-width;
	border-style: double;
	border-width: 0px 0 0px 34px;
}
.play-pause input[type="checkbox"] {
	visibility: hidden;
}
.play-pause input[type="checkbox"]:checked + label {
	border-style: solid;
	border-width: 22px 0 22px 36px;
}

#video-player .v-play-button {
	border: 0;
	background: transparent;
	box-sizing: border-box;
	width: 0;
	height: 25px;
	border-color: transparent transparent transparent #fff;
	transition: 100ms all ease;
	cursor: pointer;
	border-style: solid;
	border-width: 25px 0 25px 39px;
}
#video-player .v-play-button.playing {
	border-style: double;
	border-width: 0px 0 0px 41px;
}
#video-player .v-play-button:hover {
	border-color: transparent transparent transparent #fff;
}

#invite-video .player-wrapper {
	position: relative;
	/* padding-top: 56.25% !important; */
	/* 720 / 1280 = 0.5625 */
}

#invite-video .react-player {
	position: absolute;
	top: 0;
	left: 0;
}
}
